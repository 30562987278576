import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import EssayList from "../components/EssayList";

export const query = graphql`
  query GetEssaysByCategory($category: String) {
    allMdx(filter: { frontmatter: { categories: { in: [$category] } } }) {
      nodes {
        excerpt
        frontmatter {
          author
          slug
          categories
          title
          timeToRead
          date(formatString: "MMM Do, YYYY")
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        body
        id
      }
    }
  }
`;


const CategoryTemplate = (props) => {
  const { category } = props.pageContext;
  
  return (
    <Layout>
      <h1 className="font-extrabold text-gray-900 text-2xl capitalize">
        Category &nbsp;
        <span className="text-blue-600">{category}</span>
      </h1>
      <EssayList essays={props.data.allMdx.nodes} />
    </Layout>
  );
};

export default CategoryTemplate;


